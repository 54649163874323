import React from 'react';

export default function ProgressBar({ totalDays, daysIntoFarm }) {
  const ancho = ((daysIntoFarm / totalDays) * 100).toFixed(2);

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-amber-200">
          <div
            style={{ width: `${ancho}%` }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-amber-500`}
          ></div>
        </div>
      </div>
      <span className="mr-2">{ancho}%</span>
    </div>
  );
}

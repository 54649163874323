import React, { useContext, useState } from 'react';
import { ContextActiveFarm, ContextFarmId } from './SimplePanel';
import { ContextShowOrHideTable, ContextActiveMonth } from './GrazingNotebook.js';
import { ChevronUpIcon, ChevronDownIcon, CalendarIcon } from './Icons.js';
import { saveAs } from 'file-saver';

export function FarmButton({ farmName, center, monthName, year }) {
  const notifyOnlyShowOrHideThisMonth = useContext(ContextShowOrHideTable);
  const activeMonth = useContext(ContextActiveMonth);
  const notifyNewActiveFarm = useContext(ContextActiveFarm);

  function notifyActiveFarm() {
    if (JSON.stringify(activeMonth) === JSON.stringify({ monthName: monthName, year: year })) {
      notifyOnlyShowOrHideThisMonth({ monthName: monthName, year: year });
      notifyNewActiveFarm(farmName, center);
    } else {
      notifyNewActiveFarm(farmName, center);
    }
  }

  return (
    <button className="p-1 rounded-md hover:bg-green-500 hover:shadow-2xl" onClick={notifyActiveFarm}>
      {farmName}
    </button>
  );
}

export function ShowGrazingNotebookButton({ showGrazingNotebook }) {
  function notifyShowGrazingNotebook() {
    showGrazingNotebook();
  }

  return (
    <button
      onClick={notifyShowGrazingNotebook}
      className="flex justify-center align-middle bg-white p-1 rounded-full hover:-translate-y-1 transition duration-300"
    >
      <ChevronUpIcon width={'w-7'} heigth={'h-7'} />
    </button>
  );
}

export function HideGrazingNotebookButton({ hideGrazingNotebook }) {
  function notifyHideGrazingNotebook() {
    hideGrazingNotebook();
  }

  return (
    <button
      onClick={notifyHideGrazingNotebook}
      className="inline-block justify-center align-middle bg-white p-1 rounded-md hover:translate-y-1 transition duration-300"
    >
      <ChevronDownIcon width={'w-5'} heigth={'h-5'} />
    </button>
  );
}

export function FullMonthButton({ monthName, year }) {
  const notifyOnlyShowOrHideThisMonth = useContext(ContextShowOrHideTable);

  function notifyShowFullMonth() {
    notifyOnlyShowOrHideThisMonth({ monthName: monthName, year: year });
  }

  return (
    <button className="flex items-center" onClick={notifyShowFullMonth}>
      <span className="mr-1 text-xl font-bold">
        {monthName} - {year}
      </span>
      <div className="hover:-translate-y-1 transition duration-300">
        <ChevronUpIcon width={'w-4'} heigth={'h-4'} />
      </div>
    </button>
  );
}

export function MiniMonthButton({ monthName, year }) {
  const notifyOnlyShowOrHideThisMonth = useContext(ContextShowOrHideTable);

  function notifyShowMiniMonth() {
    notifyOnlyShowOrHideThisMonth({ monthName: monthName, year: year });
  }

  return (
    <button className="flex items-center" onClick={notifyShowMiniMonth}>
      <span className="mr-1 text-xl font-bold">
        {monthName} - {year}
      </span>
      <div className="hover:translate-y-1 transition duration-300">
        <ChevronDownIcon width={'w-4'} heigth={'h-4'} />
      </div>
    </button>
  );
}

export function DownloadGrazingNotebookButton({ startDate, endDate, showDownloadErrorDialog }) {
  const farmId = useContext(ContextFarmId);
  const [disabled, setDisabled] = useState(false);
  function callExcelEndpoint() {
    setDisabled(true);
    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Token mhYNCEsap0gPtXvKyQNaiaiu6aftur35WYhjstYgwTm1Di7pwBuKqKSm6n5q6TatcAtpXWuWU7xzxNigAyBrIhHdk1gb1yfT9zuzjwD3B5e8cANRe3qR5ityjLLj0BKD'
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      `https://grafana.innogando.com/backend/farms/${farmId}/grazing-notebook?user_id=pepogcmpmcswjkkotmmq&start=${startDate}&stop=${endDate}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `Pastoreo_${startDate}_${endDate}.xlsx`);
      })
      .catch((error) => {
        switch (error.message) {
          case '400':
            showDownloadErrorDialog('Ocurrió un error: [start,stop] son obligatorios.');
            break;
          case '404':
            showDownloadErrorDialog(
              'Ocurrió un error: no existen apariciones en el intervalo de tiempo especificado. Por favor selecciona otro intervalo.'
            );
            break;
          case '500':
            showDownloadErrorDialog('Ocurrió un error desconocido, por favor inténtalo de nuevo más tarde.');
            break;
          default:
            showDownloadErrorDialog('Ocurrió un error desconocido, por favor inténtalo de nuevo más tarde.');
        }
      });
    setDisabled(false);
  }

  return (
    <button
      disabled={disabled}
      onClick={callExcelEndpoint}
      className="flex flex-col justify-center items-center bg-white rounded-md p-2 border-2 hover:border-orange-400"
    >
      <span className="text-black text-xl font-medium">Descargar</span>
      <CalendarIcon />
    </button>
  );
}

export function CloseDialogButton({ closeDialog }) {
  return (
    <button onClick={closeDialog} className=" text-black p-1 border-2 border-black rounded-md bg-gray-200">
      Cerrar
    </button>
  );
}

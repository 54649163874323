import * as dataForge from 'data-forge';

function parseSelectedOfficialTags(selectedOfficialTags: string) {
  let parsedOfficialTags: string[] = [];

  if (selectedOfficialTags !== 'All') {
    parsedOfficialTags = selectedOfficialTags.split(' + ');
  } else {
    parsedOfficialTags.push('All');
  }

  return parsedOfficialTags;
}

function parseSelectedRumiIds(selectedRumiIds: string) {
  let parsedSelectedRumiIds = selectedRumiIds.split(',');

  if (parsedSelectedRumiIds[0].includes('{')) {
    parsedSelectedRumiIds[0] = parsedSelectedRumiIds[0].slice(1);
  }
  if (parsedSelectedRumiIds[parsedSelectedRumiIds.length - 1].includes('}')) {
    parsedSelectedRumiIds[parsedSelectedRumiIds.length - 1] = parsedSelectedRumiIds[
      parsedSelectedRumiIds.length - 1
    ].slice(0, -1);
  }

  return parsedSelectedRumiIds.map((rumi: string) => {
    if (rumi.includes('no_device') || rumi === '' || rumi === undefined || rumi === null) {
      return undefined;
    } else {
      return rumi;
    }
  });
}

export function joinSelectedOfficialTagsAndRumiIds(selectedOfficialTags: string, selectedRumiIds: string) {
  let parsedSelectedRumiIds: any = parseSelectedRumiIds(selectedRumiIds);

  // If we haven't got rumi Ids we return an empty array to notice in SimplePanel.tsx that we can't get cow's last location
  if (parsedSelectedRumiIds.filter((rumiId: string) => rumiId !== undefined).length === 0) {
    return new dataForge.DataFrame([]);
  }

  let parsedSelectedOfficialTags: string[] = parseSelectedOfficialTags(selectedOfficialTags);
  let joinedSelectedOfficialTagsAndRumiIds: any[];

  if (parsedSelectedOfficialTags[0] === 'All') {
    joinedSelectedOfficialTagsAndRumiIds = parsedSelectedRumiIds.map((rumiId: string) => {
      return { rumiId: rumiId, officialTag: undefined };
    });
  } else {
    joinedSelectedOfficialTagsAndRumiIds = parsedSelectedRumiIds.map((rumiId: string, index: number) => {
      return { rumiId: rumiId, officialTag: parsedSelectedOfficialTags[index] };
    });
  }

  return new dataForge.DataFrame(joinedSelectedOfficialTagsAndRumiIds);
}

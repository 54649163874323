import * as dataForge from 'data-forge';
import { calculateCenter } from 'MapUtils';

export function parseFarms(data: any) {
  let polygonsData: any[] = [];
  let count = 1;
  console.log('parsing farms');

  if (data.series.find((series: any) => series.refId === 'S').meta!.custom!.data! !== null) {
    data.series
      .find((series: any) => series.refId === 'S')
      .meta!.custom!.data!.plots.map((farm: any) => {
        let correctCoordinates: any[] = farm!.geometry!.coordinates![0].map((coordinatesPair: any) => [
          coordinatesPair[1],
          coordinatesPair[0],
        ]);
        polygonsData.push({
          farm_id:
            farm.provincia.toString() +
            farm.municipio.toString() +
            farm.agregado.toString() +
            farm.zona.toString() +
            farm.poligono.toString() +
            farm.parcela.toString() +
            farm.recinto.toString(),
          farm_name: `Finca ${count}`,
          coordinates: correctCoordinates,
          center: calculateCenter(correctCoordinates, true),
        });
        count++;
      });
  }
  console.log('parsed farms');

  return polygonsData;
}

export function parseAppearences(data: any, selectedOfficialTags: string[]) {
  let cowsFarmsAppearencesData: any[] = [];
  let filterBySelectedOfficialTags = selectedOfficialTags.length > 0 ? true : false;
  console.log('parseAppearences start');

  if (data.series.find((series: any) => series.refId === 'S').meta!.custom!.data! !== null) {
    data.series
      .find((series: any) => series.refId === 'S')
      .meta!.custom!.data!.notebook.map((cowsFarmsAppearences: any) => {
        if (!filterBySelectedOfficialTags || selectedOfficialTags.includes(cowsFarmsAppearences.official_tag)) {
          cowsFarmsAppearencesData.push({
            farm_id:
              cowsFarmsAppearences.provincia.toString() +
              cowsFarmsAppearences.municipio.toString() +
              cowsFarmsAppearences.agregado.toString() +
              cowsFarmsAppearences.zona.toString() +
              cowsFarmsAppearences.poligono.toString() +
              cowsFarmsAppearences.parcela.toString() +
              cowsFarmsAppearences.recinto.toString(),
            official_tag: cowsFarmsAppearences.official_tag,
            date: cowsFarmsAppearences.date,
          });
        }
      });
  }
  console.log('parseAppearences stop');

  return cowsFarmsAppearencesData;
}

// Farm means finca
export function createDataframes(polygonsData: any, cowsFarmsAppearencesData: any) {
  let dfFarms = new dataForge.DataFrame(polygonsData);
  let dfCowsFarmsAppearences = new dataForge.DataFrame(cowsFarmsAppearencesData);
  console.log('joining dataframes');

  let dfJoinAux = dfFarms.join(
    dfCowsFarmsAppearences,
    (left) => left.farm_id,
    (right) => right.farm_id,
    (left, right) => {
      return {
        farm_name: left.farm_name,
        coordinates: left.coordinates,
        farm_id: left.farm_id,
        official_tag: right.official_tag,
        date: right.date,
        center: left.center,
      };
    }
  );

  console.log('joined dataframes');
  return [dfFarms, dfJoinAux];
}

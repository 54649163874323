import React from "react";
import { Marker, Tooltip } from "react-leaflet";
import { markerIcon } from "../Icons";

export default function CowsMarkers({markersData}) {
  return (
    <>
      {markersData.map((markerData) => (
        <Marker
          position={markerData.location}
          icon={markerIcon(markerData.name !== '' ? markerData.name : markerData.officialTag)}
        >
          <Tooltip>
            <div>
              <span>Nombre: {markerData.name}</span>
              <br />
              <span>Crotal: {markerData.officialTag}</span>
            </div>
          </Tooltip>
        </Marker>
      ))}
    </>
  );
}

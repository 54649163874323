import React, { useState, createContext, useEffect } from 'react';
import Month from './Month/Month';
import { ShowGrazingNotebookButton, HideGrazingNotebookButton, DownloadGrazingNotebookButton } from './Buttons';
import { DialogFullScreen, DialogDownloadError } from './Dialogs';

export const ContextShowOrHideTable = createContext();
export const ContextActiveMonth = createContext();

export default function GrazingNotebook({ startDate, endDate, dataframe, heightContainer }) {
  const [onlyThisMonth, setOnlyThisMonth] = useState(undefined);
  const [grazingNotebook, setGrazingNotebook] = useState(false);
  const [daysBetweenDates, setDaysBetweenDates] = useState();
  const [formatedDataframe, setFormatedDataframe] = useState();
  const [showDialogFullScreen, setShowDialogFullScreen] = useState(false);
  const [showDialogDownloadError, setShowDialogDownloadError] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState();
  let heigthGrazingNotebook = onlyThisMonth === undefined ? 32 : 80;

  // Prevent dialogs to overlap one another
  useEffect(() => {
    if (showDialogFullScreen && showDialogDownloadError) {
      setShowDialogDownloadError(false);
    }
  }, [showDialogFullScreen]);
  useEffect(() => {
    if (showDialogFullScreen && showDialogDownloadError) {
      setShowDialogFullScreen(false);
    }
  }, [showDialogDownloadError]);

  useEffect(() => {
    setGrazingNotebook(false);
    let fechas = getDaysBetweenDates(startDate, endDate);
    setDaysBetweenDates(fechas);
    setFormatedDataframe(destructureDataframeDate(dataframe));
  }, [dataframe]);

  useEffect(() => {
    if (heightContainer > 750 && showDialogFullScreen) {
      setShowDialogFullScreen(false);
    }
  }, [heightContainer]);

  function changeOnlyThisMonthState(monthObject) {
    if (JSON.stringify(onlyThisMonth) === JSON.stringify(monthObject)) {
      setOnlyThisMonth(undefined);
    } else {
      setOnlyThisMonth(monthObject);
    }
  }
  
  function showGrazingNotebook() {
    if (heightContainer > 500) {
      setShowDialogFullScreen(false);
      setGrazingNotebook(true);
    } else {
      setShowDialogFullScreen(true);
    }
  }

  function hideGrazingNotebook() {
    setGrazingNotebook(false);
  }

  function showDownloadErrorDialog(message) {
    setDownloadErrorMessage(message);
    setShowDialogDownloadError(true);
  }

  return (
    <>
      {/*Open button*/}
      {!grazingNotebook && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            zIndex: 1000,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          className="mb-1"
        >
          <ShowGrazingNotebookButton showGrazingNotebook={showGrazingNotebook} />
        </div>
      )}
      {/*Dialog indicating how to change to full screen*/}
      {showDialogFullScreen && <DialogFullScreen setDialogState={setShowDialogFullScreen} />}
      {/*Download Grazing Notebook Button*/}
      <div style={{ position: 'absolute', top: '3%', right: '2%', zIndex: 1000 }} className="shadow-md">
        <DownloadGrazingNotebookButton
          startDate={startDate.toFormat('yyyy-MM-dd')}
          endDate={endDate.toFormat('yyyy-MM-dd')}
          showDownloadErrorDialog={showDownloadErrorDialog}
        />
      </div>
      {showDialogDownloadError && (
        <DialogDownloadError setDialogState={setShowDialogDownloadError} message={downloadErrorMessage} />
      )}
      {/*Grazing Notebook*/}
      {grazingNotebook && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            zIndex: 1000,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          className={`flex flex-col ${heigthGrazingNotebook === 32 ? 'max-h-[32%]' : 'max-h-[80%]'} max-w-[99%]`}
        >
          <div className="flex justify-center mb-1">
            <HideGrazingNotebookButton hideGrazingNotebook={hideGrazingNotebook} />
          </div>
          <div className={`flex flex-row  gap-x-4 text-black overflow-x-auto`}>
            <ContextActiveMonth.Provider value={onlyThisMonth}>
              <ContextShowOrHideTable.Provider value={changeOnlyThisMonthState}>
                {onlyThisMonth === undefined
                  ? daysBetweenDates.map((monthData) => {
                      return (
                        <Month
                          key={{ monthName: monthData.monthName, year: monthData.year }}
                          monthData={monthData}
                          maxWidth={
                            daysBetweenDates.length === 1
                              ? 'max-w-full'
                              : daysBetweenDates.length === 2
                              ? 'max-w-5xl'
                              : 'max-w-2xl'
                          }
                          appearencesData={formatedDataframe.where(
                            (row) => row.month === monthData.monthNumber && row.year === monthData.year
                          )}
                          maxHeigthTable={'max-h-[86%]'}
                          showOrHide={'show'}
                          hideMonth={false}
                        />
                      );
                    })
                  : (() => {
                      let maxWidth = undefined;
                      let maxHeigthTable = undefined;
                      let hideMonth = undefined;

                      let months = daysBetweenDates.map((monthData) => {
                        if (monthData.monthName === onlyThisMonth.monthName && monthData.year === onlyThisMonth.year) {
                          maxWidth = 'max-w-full';
                          maxHeigthTable = 'max-h-[94%]';
                          hideMonth = false;
                        } else {
                          maxWidth = 'max-w-0';
                          maxHeigthTable = 'max-h-0';
                          hideMonth = true;
                        }

                        return (
                          <Month
                            key={{ monthName: monthData.monthName, year: monthData.year }}
                            monthData={monthData}
                            maxWidth={maxWidth}
                            appearencesData={formatedDataframe.where(
                              (row) => row.month === monthData.monthNumber && row.year === monthData.year
                            )}
                            maxHeigthTable={maxHeigthTable}
                            showOrHide={'hide'}
                            hideMonth={hideMonth}
                          />
                        );
                      });
                      return months;
                    })()}
              </ContextShowOrHideTable.Provider>
            </ContextActiveMonth.Provider>
          </div>
        </div>
      )}
    </>
  );
}

function getDaysBetweenDates(start, end) {
  const dateRange = [];

  while (start <= end) {
    const monthName = start.toFormat('MMMM');
    const year = start.year;
    const day = start.day;

    const existingMonth = dateRange.find(
      (entry) => entry.monthName === monthTomonthToMes(monthName) && entry.year === year
    );
    if (existingMonth) {
      existingMonth.days.push(day);
    } else {
      dateRange.push({
        monthName: monthTomonthToMes(monthName),
        days: [day],
        monthNumber: start.month,
        year: start.year,
      });
    }

    start = start.plus({ days: 1 });
  }

  return dateRange;
}

function destructureDataframeDate(dataframe) {
  return dataframe.select((row) => ({
    center: row.center,
    official_tag: row.official_tag,
    day: parseInt(row.date.split('-')[2], 10),
    month: parseInt(row.date.split('-')[1], 10),
    year: parseInt(row.date.split('-')[0], 10),
    farm_id: row.farm_id,
    farm_name: row.farm_name,
  }));
}

function monthTomonthToMes(month) {
  let monthToMes = '';

  switch (month) {
    case 'January':
      monthToMes = 'Enero';
      break;
    case 'February':
      monthToMes = 'Febrero';
      break;
    case 'March':
      monthToMes = 'Marzo';
      break;
    case 'April':
      monthToMes = 'Abril';
      break;
    case 'May':
      monthToMes = 'Mayo';
      break;
    case 'June':
      monthToMes = 'Junio';
      break;
    case 'July':
      monthToMes = 'Julio';
      break;
    case 'August':
      monthToMes = 'Agosto';
      break;
    case 'September':
      monthToMes = 'Septiembre';
      break;
    case 'October':
      monthToMes = 'Octubre';
      break;
    case 'November':
      monthToMes = 'Noviembre';
      break;
    case 'December':
      monthToMes = 'Diciembre';
    default:
      break;
  }

  return monthToMes;
}

export function parseCoordinatesHeatLayer(data: any) {
  let coordinates: any[] = [];
  let points: any[] = [];
  let timesToAdd: number[] = [];

  if (data.series.find((series: any) => series.refId === 'L').meta!.custom!.data!.length > 0) {
    data.series
      .find((series: any) => series.refId === 'L')
      .meta!.custom!.data.map((item: any) => {
        points.push([item['latitude'], item['longitude']]);
        timesToAdd.push(item['_value']);
      });

    for (let i = 0; i < points.length; i++) {
      coordinates = coordinates.concat(Array(timesToAdd[i]).fill(points[i]));
    }
  }

  return coordinates;
}

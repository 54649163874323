import React from 'react';
import { Marker, Polygon } from 'react-leaflet';
import FarmToolTip from './FarmToolTip/FarmToolTip';
import { calculateCenter } from '../MapUtils';
import { farmNumberIcon } from '../Icons';

export default function FarmsPolygons({ dfFarms, dfAppearences, polygonsRefs, timeIntervalAsText, timeInterval }) {
  let appearencesByFarm = dfAppearences
    .groupBy((row) => row.farm_id)
    .select((group) => ({
      farm_id: group.first().farm_id,
      num_cows: group
        .select((row) => row.official_tag)
        .distinct()
        .count(),
      days_into_farm: group
        .select((row) => row.date)
        .distinct()
        .count(),
    }));

  let appearencesAndCoordinatesByFarm = dfFarms.join(
    appearencesByFarm,
    (left) => left.farm_id,
    (right) => right.farm_id,
    (left, right) => {
      return {
        farm_name: left.farm_name,
        farm_id: left.farm_id,
        num_cows: right.num_cows,
        days_into_farm: right.days_into_farm,
        coordinates: left.coordinates,
      };
    }
  );

  let appearencesAndCoordinatesByFarmArray = appearencesAndCoordinatesByFarm.toArray();

  return (
    <>
      {appearencesAndCoordinatesByFarmArray.map((rowFarm, index) => {
        return (
          <Polygon
            key={rowFarm.farm_id}
            ref={polygonsRefs[parseInt(rowFarm.farm_name.match(/\d+/)[0]) - 1]}
            positions={rowFarm.coordinates}
            color={'red'}
          >
            <FarmToolTip
              farmNumber={parseInt(rowFarm.farm_name.match(/\d+/)[0])}
              farmId={rowFarm.farm_id}
              numCows={rowFarm.num_cows}
              timeIntervalText={timeIntervalAsText}
              daysIntoFarm={rowFarm.days_into_farm}
              intervalAsDays={Math.ceil(timeInterval.as('days'))}
            />
            <Marker
              position={calculateCenter(rowFarm.coordinates, false)}
              icon={farmNumberIcon(parseInt(rowFarm.farm_name.match(/\d+/)[0]))}
            ></Marker>
          </Polygon>
        );
      })}
    </>
  );
}

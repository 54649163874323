import React from 'react';
import { Tooltip } from 'react-leaflet';
import ProgressBar from './ProgressBar';

export default function FarmToolTip({
  farmNumber,
  farmId,
  numCows,
  timeIntervalText,
  daysIntoFarm,
  intervalAsDays,
}) {
  return (
    <Tooltip>
      <div>
        <span className="flex justify-center">Finca {farmNumber}</span>
        Código SigPac: {farmId}
        <br />
        Nº de vacas: {numCows}
        <br />
        Duración intervalo: {timeIntervalText}
        <br />
        Nº de días en la finca: {daysIntoFarm}
        <ProgressBar totalDays={intervalAsDays} daysIntoFarm={daysIntoFarm} />
      </div>
    </Tooltip>
  );
}

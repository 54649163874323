import * as dataForge from 'data-forge';

export function joinCowsCredentialsAndLastLocation(data: any, selectedCowsOfficialTags: string[]) {
  let dfCowsLastLocation = new dataForge.DataFrame(parseCowsLastLocation(data));

  if (dfCowsLastLocation.toArray().length === 0) {
    return [];
  }

  let dfCowsCredentials = new dataForge.DataFrame(parseCowsCredentials(data, selectedCowsOfficialTags));

  let joinedCowsLocationAndCredentials = dfCowsLastLocation.join(
    dfCowsCredentials,
    (left) => left.rumiId,
    (right) => right.rumiId,
    (left, right) => {
      return {
        rumiId: left.rumiId,
        location: left.location,
        name: right.name,
        officialTag: right.officialTag,
      };
    }
  );
  let finalData = joinedCowsLocationAndCredentials.toArray();

  return finalData;
}

export function parseCowsLastLocation(data: any) {
  let locationsAndRumiIds: any[] = [];
  // device_name == rumiId

  if (data.series.find((series: any) => series.refId === 'A') !== undefined) {
    if (data.series.find((series: any) => series.refId === 'A').meta.custom.data.length > 0) {
      locationsAndRumiIds = data.series
        .find((series: any) => series.refId === 'A')
        .meta.custom.data.map((locationData: any) => {
          return { rumiId: locationData.device_name, location: locationData._location.split('::') };
        });
    }
  }

  return locationsAndRumiIds;
}

export function parseCowsCredentials(data: any, selectedCowsOfficialTags: string[]) {
  let cowsCredentials: any[] = [];
  let filter = true;

  if (selectedCowsOfficialTags.length === 0) {
    filter = false;
  }

  if (data.series.find((series: any) => series.refId === 'B').meta!.custom!.data! !== null) {
    // PELIGRO! Existen casos nos que no temos nin devEUI, nin rumiId! Caso granja de Ero
    let realData = data.series.find((series: any) => series.refId === 'B').meta.custom.data;

    if (filter) {
      cowsCredentials = realData
        .filter((cowData: any) => selectedCowsOfficialTags.includes(cowData.official_tag))
        .map((cowData: any) => {
          return {
            name: cowData.name,
            officialTag: cowData.official_tag,
            rumiId: cowData.rumi_id,
            devEUI: cowData.devEUI,
          };
        });
    } else {
      cowsCredentials = realData.map((cowData: any) => {
        return {
          name: cowData.name,
          officialTag: cowData.official_tag,
          rumiId: cowData.rumi_id,
          devEUI: cowData.devEUI,
        };
      });
    }
  }

  return cowsCredentials;
}

import { DateTime } from 'luxon';

export function parseTimeInterval(data: any) {
  let startDate: any;
  let endDate: any;
  let timeInterval: any;
  let timeIntervalAsText: any;

  startDate = DateTime.fromISO(data.timeRange.from.toISOString());
  endDate = DateTime.fromISO(data.timeRange.to.toISOString());

  timeInterval = endDate.diff(startDate);

  if (
    Math.floor(timeInterval.as('hours') % 24) === 23 &&
    Math.floor(timeInterval.as('minutes') % 60) === 59 &&
    Math.floor(timeInterval.as('seconds') % 60) === 59
  ) {
    timeInterval = timeInterval.plus({ seconds: 1 });
  }

  const days = Math.floor(timeInterval.as('days'));
  const hours = Math.floor(timeInterval.as('hours') % 24);
  const minutes = Math.floor(timeInterval.as('minutes') % 60);
  const seconds = Math.floor(timeInterval.as('seconds') % 60);

  timeIntervalAsText = `${days > 0 ? days + (days > 1 ? ' días ' : ' día ') : ''}${
    hours > 0 ? hours + (hours > 1 ? ' horas ' : ' hora ') : ''
  }${minutes > 0 ? minutes + (minutes > 1 ? ' minutos ' : ' minuto ') : ''}${
    seconds > 0 ? seconds + (seconds > 1 ? ' segundos ' : ' segundo') : ''
  }`;

  return [startDate, endDate, timeInterval, timeIntervalAsText];
}

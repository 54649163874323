import React from 'react';
import { InfoIcon } from './Icons';
import { CircleCrossIcon } from './Icons';
import { CloseDialogButton } from './Buttons';

function DialogTemplate({ message, setDialogState, iconColor }) {
  function closeDialog() {
    setDialogState(false);
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
      className="flex flex-col justify-center items-center bg-white p-2 gap-2 rounded-md shadow-md max-w-[98%]"
    >
      <div className="flex flex-row align-middle justify-center gap-1">
        <InfoIcon color={iconColor}/>
        <span className="text-black">{message}</span>
      </div>
      <CloseDialogButton closeDialog={closeDialog} />
    </div>
  );
}

export function DialogFullScreen({ setDialogState }) {
  return (
    <DialogTemplate
      message={'Abra el mapa a pantalla completa: Pinche en el mapa y luego pulse la letra v (Si un trozo de mapa no carga recargue la página)'}
      setDialogState={setDialogState}
      iconColor={"text-blue-600"}
    />
  );
}

export function DialogDownloadError({ setDialogState, message }) {
  return (
    <DialogTemplate
      message={message}
      setDialogState={setDialogState}
      iconColor={"text-orange-600"}
    />
  );
}

export function NoDataAdvertisement({ noDataIndicators }) {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          zIndex: 1000,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        className="flex flex-row gap-2 flex-wrap align-middle justify-center sm:max-w-[75%] lg:max-w-[85%] mt-3"
      >
        {noDataIndicators.map((noDataIndicator) => (
          <NoDataWidget noDataIndicator={noDataIndicator} />
        ))}
      </div>
    </>
  );
}

function NoDataWidget({ noDataIndicator }) {
  return (
    <div className="flex flex-row align-middle justify-center bg-white p-2 gap-1 rounded-full shadow-md">
      <CircleCrossIcon color={'text-red-500'} />
      <span className="text-black">{textError(noDataIndicator)}</span>
    </div>
  );
}

function textError(noDataIndicator) {
  let textError = '';

  switch (noDataIndicator) {
    case 'farmsAndAppearences':
      textError = 'No fincas y no apariciones';
      break;
    case 'heatLayer':
      textError = 'No mapa de calor';
      break;
    case 'cowsLocation':
      textError = 'No última ubicación';
      break;
    default:
      textError = 'Error';
      break;
  }

  return textError;
}

import React from 'react';
import { OkIcon, CircleCrossIcon } from '../Icons';

export function Appearences({ number }) {
  return (
    <>
      <div className="flex flex-row">
        <div class="flex justify-center flex-grow self-end">
          <OkIcon/>
        </div>
        <div className="mb-2">
          <div className="flex-center bg-green-500 rounded-full px-1 py-0.5">
            <span className="text-sm">{number}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export function NoAppearences() {
  return (
    <>
      <div class="flex justify-center flex-grow self-end">
        <CircleCrossIcon color={'text-orange-400'}/>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import * as dataForge from 'data-forge';
import { FarmButton, FullMonthButton, MiniMonthButton } from '../Buttons';
import { LoadingIcon } from '../Icons';
import { Appearences, NoAppearences } from './TableElements';

export default function Month({ monthData, appearencesData, maxWidth, maxHeigthTable, showOrHide, hideMonth }) {
  const [tableData, setTableData] = useState(undefined);
  const [sortField, setSortField] = useState();
  const [farmsNumber, setFarmsNumber] = useState(60);

  useEffect(() => {
    const filteredDatesDataframe = filterAppearencesDataToDateRange(monthData, appearencesData);

    const totalAppearencesByDayDataframe = calculateAppearences(filteredDatesDataframe);

    let allFarms = appearencesData
      .select((row) => ({ farm_name: row.farm_name, center: row.center }))
      .distinct((row) => row.farm_id);

    setFarmsNumber(allFarms.count());

    let dataframeAllCombinations = allCombinationsBetweenDaysAndFarms(allFarms, monthData.days);

    const dataframeWithAppearences = fillAllCombinationsWithAppearences(
      dataframeAllCombinations,
      totalAppearencesByDayDataframe
    );

    const dataframeWithAcumulatedAppearences = calculateDaysWithAppearences(dataframeWithAppearences);

    let formatedData = loadMonthAppearences(
      monthData.days.length,
      dataframeWithAcumulatedAppearences.toArray(),
      monthData.monthName,
      monthData.year
    );

    setTableData(formatedData);
  }, [appearencesData]);

  const handleSort = (e) => {
    if (e.sortField === 'farm_name') {
      if (sortField === 'days_with_appearences') {
        setSortField(null);
      } else {
        setSortField('days_with_appearences');
      }
    } else {
      setSortField(e.sortField);
    }
  };

  return (
    <div
      className={`${
        hideMonth === true ? 'hidden' : 'flex'
      } flex-col sm:mx-auto lg:mx-0 grow w-full max-h-full ${maxWidth} gap-y-3 text-center bg-white p-3 rounded-lg shadow-md`}
    >
      <div className="flex justify-center items-center">
        {showOrHide === 'show' ? (
          <FullMonthButton monthName={monthData.monthName} year={monthData.year} />
        ) : (
          <MiniMonthButton monthName={monthData.monthName} year={monthData.year} />
        )}
      </div>
      {tableData === undefined && <LoadingIcon />}
      {tableData !== undefined && (
        <div className={maxHeigthTable}>
          <DataTable
            value={tableData}
            scrollable
            removableSort
            scrollHeight="flex"
            size="small"
            sortField={sortField}
            onSort={handleSort}
            sortOrder={-1}
          >
            <Column
              field="farm_name"
              sortable={true}
              frozen
              alignHeader={'center'}
              style={{ textAlign: 'center' }}
              className="font-bold first-line:text-sm"
            ></Column>
            {monthData.days.map((day) => {
              return (
                <Column
                  field={day.toString()}
                  body={(rowData) =>
                    farmsNumber <= 50 ? (
                      rowData[day.toString()] > 0 ? (
                        <Appearences number={rowData[day.toString()]} />
                      ) : (
                        <NoAppearences />
                      )
                    ) : rowData[day.toString()] > 0 ? (
                      rowData[day.toString()]
                    ) : (
                      '-'
                    )
                  }
                  header={day}
                  sortable={true}
                  style={{ textAlign: 'center' }}
                  className="text-sm"
                ></Column>
              );
            })}
          </DataTable>
        </div>
      )}
    </div>
  );
}

function filterAppearencesDataToDateRange(monthData, appearencesData) {
  return appearencesData.where(
    (row) => row.day >= monthData.days[0] && row.day <= monthData.days[monthData.days.length - 1]
  );
}

function calculateAppearences(dataframe) {
  let caca = dataframe
    .groupBy((row) => [row.farm_name, row.day])
    .select((group) => ({
      farm_name: group.first().farm_name,
      day: group.first().day,
      appearences: group.count(),
      official_tags: group.getSeries('official_tag').toArray(), // Por se se necesita no futuro
      center: group.first().center,
    }));

  return caca;
}

function allCombinationsBetweenDaysAndFarms(allFarms, days) {
  // Add column to force cross join
  const daysDataframe = new dataForge.DataFrame(
    days.map((day) => ({ day, appearences: 0, official_tags: [] }))
  ).withSeries('key', new dataForge.Series(Array(days.length).fill(1)));

  let allFarmsAddedColumnKey = allFarms.withSeries('key', new dataForge.Series(Array(allFarms.count()).fill(1)));

  let allFarmsWithkey = allFarmsAddedColumnKey.transformSeries({
    key: () => 1,
  });

  return allFarmsWithkey.join(
    daysDataframe,
    (left) => left.key,
    (right) => right.key,
    (left, right) => {
      return {
        farm_name: left.farm_name,
        day: right.day,
        appearences: right.appearences,
        official_tags: right.official_tags,
        center: left.center,
      };
    }
  );
}

function fillAllCombinationsWithAppearences(allCombinations, filteredDataframe) {
  const concatedDataframe = new dataForge.DataFrame(
    allCombinations.bake().toArray().concat(filteredDataframe.bake().toArray())
  );

  // concatedDataframe has 2 rows for 1 day, one row always has 0 appearences and the other could have more (the real appearences),
  // so we choose the row with highest appearences
  const finalDataframe = concatedDataframe
    .groupBy((row) => [row.farm_name, row.day])
    .select((group) => {
      const highestValueRow = group.orderByDescending((row) => row.appearences).first();
      return highestValueRow;
    });

  return finalDataframe;
}

function calculateDaysWithAppearences(dataframe) {
  const groupedDf = dataframe
    .groupBy((row) => row.farm_name)
    .select((group) => {
      const df = new dataForge.DataFrame(group);
      return {
        farm_name: df.first().farm_name,
        days_with_appearences: df
          .getSeries('appearences')
          .toArray()
          .filter((appearences) => appearences > 0).length,
      };
    });

  const dfDaysWithAppearences = dataframe.join(
    groupedDf,
    (left) => left.farm_name,
    (right) => right.farm_name,
    (left, right) => {
      return {
        ...left,
        days_with_appearences: right.days_with_appearences,
      };
    }
  );

  return dfDaysWithAppearences;
}

function loadMonthAppearences(days, dataframe, monthName, year) {
  let formatedData = [];
  let auxObject = {};

  dataframe.map((row, index) => {
    auxObject[row.day.toString()] = row.appearences;

    if ((index + 1) % days === 0) {
      auxObject['days_with_appearences'] = row.days_with_appearences;
      auxObject['farm_name'] = (
        <FarmButton
          key={monthName + row.farm_name}
          farmName={row.farm_name}
          center={row.center}
          monthName={monthName}
          year={year}
        />
      );
      formatedData.push(auxObject);
      auxObject = {};
    }
  });

  return formatedData;
}

/* eslint-disable */
import React, { useState, createContext, useEffect, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Map, TileLayer, Marker, Polygon, Tooltip, WMSTileLayer, Popup } from 'react-leaflet';
import './css/leaflet.css';
import moment from 'moment';
import * as WMS from 'leaflet.wms';
import CustomWMSLayer from './CustomWMSLayer';
import GrazingNotebook from './GrazingNotebook';
import { parseAppearences, parseFarms, createDataframes } from './FarmsAndAppearences/ParserFarms&Appearences';
import { joinSelectedOfficialTagsAndRumiIds } from 'ParserSelectedCows';
import { joinCowsCredentialsAndLastLocation } from './CowsMarkers/ParserLastLocation&CowCredentials';
import { parseTimeInterval } from 'ParserDates';
import { parseCoordinatesHeatLayer } from 'ParseHeatLayer';
import { changeActiveFarm } from 'MapUtils';
import { handleHeatLayer, addHeatLayerToMap, removeHeatLayerFromMap } from 'MapUtils';
import { NoDataAdvertisement } from './Dialogs';
import FarmsPolygons from './FarmsAndAppearences/FarmsPolygons';
import CowsMarkers from './CowsMarkers/CowsMarkers';

interface Props extends PanelProps<SimpleOptions> {}

export const ContextActiveFarm = createContext({});
export const ContextFarmId = createContext({});

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const mapRef = useRef<Map>(null);

  const [heatLayerCoordinates, setHeatLayerCoordinates] = useState<any>();

  const heatLayerRef = useRef<any>(null);

  const [polygonsRefs, setPolygonsRefs] = useState<any>();

  const [markersData, setMarkersData] = useState<any>();

  const [parsedDataIndicators, setParsedDataIndicators] = useState<any>({
    farmsAndAppearences: false,
    heatLayer: false,
    cowsLocation: false,
  });

  const [mapCenter, setMapCenter] = useState<number[]>();

  const [startDate, setStartDate] = useState<any>();

  const [endDate, setEndDate] = useState<any>();

  const [timeInterval, setTimeInterval] = useState<any>();

  const [timeIntervalAsText, setTimeIntervalAsText] = useState<string>();

  const [dfFarms, setDfFarms] = useState<any>();

  const [dfAppearences, setDfAppearences] = useState<any>();

  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    let farmsData: any = [];
    let dfSelectedCowsCredentials = joinSelectedOfficialTagsAndRumiIds(
      replaceVariables('${farmCows:text}'),
      replaceVariables('$farmCows')
    );

    farmsData = parseFarms(data);

    let cowsFarmsAppearencesData = parseAppearences(data, dfSelectedCowsCredentials.getSeries('officialTag').toArray());
    if (farmsData.length > 0) {
      // Array to have a reference of every farm polygon
      setPolygonsRefs(farmsData.map(() => React.createRef()));

      const [dfFarmsAux, dfAppearencesAux] = createDataframes(farmsData, cowsFarmsAppearencesData);
      setDfFarms(dfFarmsAux);
      setDfAppearences(dfAppearencesAux);

      const [startDateAux, endDateAux, timeIntervalAux, timeIntervalAsTextAux] = parseTimeInterval(data);
      setStartDate(startDateAux);
      setEndDate(endDateAux);
      setTimeInterval(timeIntervalAux);
      setTimeIntervalAsText(timeIntervalAsTextAux);
    }

    let heatLayerCoordinates = parseCoordinatesHeatLayer(data);
    if (heatLayerCoordinates.length > 0) {
      setHeatLayerCoordinates(heatLayerCoordinates);
      handleHeatLayer(mapRef, heatLayerRef, heatLayerCoordinates);
    } else {
      if (mapRef.current !== null && heatLayerRef.current !== null) {
        removeHeatLayerFromMap(mapRef.current.leafletElement, heatLayerRef.current);
      }
    }

    let cowsLastLocation = joinCowsCredentialsAndLastLocation(
      data,
      dfSelectedCowsCredentials.getSeries('officialTag').toArray()
    );

    if (cowsLastLocation.length > 0) {
      setMarkersData(cowsLastLocation);
    }

    setMapCenter(
      cowsLastLocation.length > 0
        ? cowsLastLocation[0].location
        : heatLayerCoordinates.length > 0
        ? heatLayerCoordinates[0]
        : farmsData.length > 0
        ? farmsData[0].center
        : undefined
    );
    setParsedDataIndicators({
      farmsAndAppearences: cowsFarmsAppearencesData.length > 0 ? true : false,
      heatLayer: heatLayerCoordinates.length > 0 ? true : false,
      cowsLocation: cowsLastLocation.length > 0 ? true : false,
    });
    setShowContent(true);
  }, [data]);

  // First render heat layer
  useEffect(() => {
    if (mapRef.current !== null && parsedDataIndicators.heatLayer) {
      const mapInstance = mapRef.current.leafletElement;
      heatLayerRef.current = addHeatLayerToMap(mapInstance, heatLayerCoordinates);
    }
  }, [showContent]);

  function notifyChangeActiveFarm(farm_name: string, center: number[]) {
    changeActiveFarm(farm_name, center, mapRef, polygonsRefs);
  }

  const styles = {
    wrapper: {
      height: '100%',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
    },
    map: {
      flex: 1,
    },
  };
  return (
    <>
      {showContent && (
        <>
          <div style={styles.wrapper}>
            <Map ref={mapRef} key={'map'} style={styles.map} center={mapCenter} zoom={15}>
              <TileLayer
                url={'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'}
                maxNativeZoom={20}
                maxZoom={20}
              />
              <>
                {parsedDataIndicators.farmsAndAppearences && (
                  <FarmsPolygons
                    dfFarms={dfFarms}
                    dfAppearences={dfAppearences}
                    polygonsRefs={polygonsRefs}
                    timeIntervalAsText={timeIntervalAsText}
                    timeInterval={timeInterval}
                  />
                )}
              </>
              {parsedDataIndicators.cowsLocation && <CowsMarkers markersData={markersData} />}
            </Map>
          </div>
          {parsedDataIndicators.farmsAndAppearences && (
            <ContextFarmId.Provider value={replaceVariables('$farmId')}>
              <ContextActiveFarm.Provider value={notifyChangeActiveFarm}>
                <GrazingNotebook
                  startDate={startDate}
                  endDate={endDate}
                  dataframe={dfAppearences}
                  heightContainer={height}
                />
              </ContextActiveFarm.Provider>
            </ContextFarmId.Provider>
          )}
          {(!parsedDataIndicators.farmsAndAppearences || !parsedDataIndicators.heatLayer) && (
            <NoDataAdvertisement
              /* Convert to array the keys that indicates no data*/
              noDataIndicators={Object.keys(parsedDataIndicators).filter((key) => parsedDataIndicators[key] === false)}
            />
          )}
        </>
      )}
    </>
  );
};

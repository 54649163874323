import L from 'leaflet';
import 'leaflet.heat';
import './css/leaflet.css';
import './css/mapMarkerStyle.css';

export function changeActiveFarm(farm_name: string, center: number[], mapRef: any, polygonsRefs: any) {
  const match = farm_name.match(/\d+/);
  const polygonRef = polygonsRefs[parseInt(match![0], 10) - 1];

  // Selected farm to red color
  polygonsRefs.forEach((polygonRef: any) => {
    if (polygonRef.current != null) {
      const leafletPolygon = polygonRef.current.leafletElement;
      if (leafletPolygon.options.fillColor === '#00008B') {
        leafletPolygon.setStyle({ fillColor: 'red', color: 'red' });
        leafletPolygon.bringToBack();
      }
    }
  });

  // New selected farm to blue color
  if (polygonRef.current) {
    const polygonInstance = polygonRef.current.leafletElement;
    polygonInstance.setStyle({ fillColor: '#00008B', color: '#00008B' });
    polygonInstance.bringToFront();
  }
  // Change map center
  if (mapRef.current) {
    const mapInstance = mapRef.current.leafletElement;
    mapInstance.setView(center);
  }
}

export function calculateCenter(coordinates: any, asArray: boolean) {
  const bounds = L.latLngBounds(coordinates);
  const centerPoint = bounds.getCenter();
  if (!asArray) {
    return centerPoint;
  } else {
    return [centerPoint.lat, centerPoint.lng];
  }
}

export function handleHeatLayer(mapRef: any, heatLayerRef: any, heatLayerCoordinates: any) {
  if (mapRef.current !== null) {
    const map = mapRef.current.leafletElement;

    if (heatLayerRef.current !== null) {
      removeHeatLayerFromMap(map, heatLayerRef.current);
    }

    heatLayerRef.current = addHeatLayerToMap(map, heatLayerCoordinates);
  }
}

export function addHeatLayerToMap(map: any, heatLayerCoordinates: any[]) {
  // @ts-ignore
  return L.heatLayer(heatLayerCoordinates, {
    gradient: { 0.4: 'blue', 0.65: 'lime', 1: 'red' },
    blur: 50,
  }).addTo(map);
}

export function removeHeatLayerFromMap(map: any, heatLayer: any) {
  map.removeLayer(heatLayer);
}
